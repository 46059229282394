import * as React from "react";
import { useEffect, useState } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import Explore from "../components/atoms/explore";
import FreeTrial from "../components/atoms/freeTrial";
import AOS from "aos";
import "aos/dist/aos.css";
import Head from "../components/ogranisms/head";
import LeftRight from "../components/molecules/LeftRight/LeftRight";
import { homeSections, homeSlider } from "../constants/home";
import MainSlider from "../components/molecules/MainSlider/MainSlider";
import { SingleModule } from "@master-wizr/mw-trial";
import "@master-wizr/mw-trial/dist/index.css";
import NoSSR from 'react-no-ssr';
import  Fullscreen  from '../images/fullscreen.svg';
import  ExitFullscreen  from '../images/exitfullscreen.svg';
import ArticleHome from "../components/ogranisms/ArticleHome/ArticleHome";
import ToolMatch from "../components/molecules/ToolMatch/ToolMatch";
import JoneSection from "../components/molecules/JoneSection/JoneSection";

const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [width, setWidth] = useState(0)
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const data = [
    {
      title: "1. Workspace Tools",
      desc: "Behind how we daily communicate and connect is so-called workspace tools. Our  innovative software suite is giving users better ways to present, store, engage in conversations, collaborate and share information.",
    },
    {
      title: "2. All-in-One",
      desc: "Today we have many specialized tools which don't talk together, and they don't look the same. Our All-in-One approach gives a better user experience for how you communicate and connect.",
    },
    {
      title: "3. MASTER WiZR Approach",
      desc: "Experience the future of work by mastering all tools utilizing one single interface and, one sophisticated design for easy navigation and use. Specialized for preparing and holding meetings with information at your fingertips. ",
    },
  ];

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <>
            <button className="fullscreenBtn" onClick={toggleFullscreen}>
          {isFullscreen ? (
            <>
              <ExitFullscreen />
              <div className="fullscreenTooltip">
                <h3 className="tooltip">Exit Full Screen</h3>
              </div>
            </>
          ) : (
            <>
              <Fullscreen />
              <div className="fullscreenTooltip">
                <h3 className="tooltip">Go Full Screen</h3>
              </div>
            </>
          )}
        </button>
      <Head
        title="Home - Master WiZR "
        description="Revolutionizing Presentations - Cutting Edge Sharing for Business. Get your MASTER WiZR free trial today. It is the best presentation tool."
        ogTitle="Home"
      />
      <Header />
      <main>
        <video className="homeMainVideo" src="https://res.cloudinary.com/dsg2ktuqk/video/upload/v1722011425/Templates/Banner4.2_2_s8fcje.mp4" autoPlay loop muted />
           <JoneSection />
        <NoSSR>
         <div className="homeProducts">
                <SingleModule location={"/trial-steps"} module="library"/>
           </div>
           </NoSSR>
           <ToolMatch />
        {homeSections.slice(0, 1).map((d, i) => {
          return <LeftRight key={i} {...d} />;
        })}
          <Explore homebtn extraClass="homeSection" btnText="About us" link="/about-us" subtitle="Elegant, Clean & Modern" title="Simply stand out" description="MASTER WiZR makes it easy to improve everyday communication, hold impactful meetings, build deeper connections, and create a strong virtual presence"/>
        {homeSections.slice(1).map((d, i) => {
          return <LeftRight key={i} {...d} />;
        })}
        <ArticleHome />
      {/* <MainSlider extraClass="teamSlider" slideWidth="85%" height="44vh" title="From Pixels to Handshakes" data={aboutSlider} slidesNumber={1}/> */}
        <MainSlider extraClass="homeSlider" height="100%" slidesNumber={1} title="Connect and innovate with us" description="Join us, virtually or in person, for creative collaboration." data={homeSlider}/>
        {/* <VisualConnection />
        <ControlPanel />
        <DarkCloseup />
        <ControlPanelClone />
        <BrightCloseup />
        <FreeVideos />
        <Unique
          list={connectlist3}
          extraClass="gridfour"
          title="Reimagine Presentation and Collaboration"
          description={`MASTER WiZR, designed for ambitious businesses of all sizes.`}
        />
        <DelightSection />
        <div className={styles.bannerbottom}>
          <h1><Link to="/get-started">Sign Up Today</Link></h1>
          {width >= 768 ? <img
            alt="sign up"
            src={
              "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680903274/web%20images/login12-0732d10312a2c2a21ee72d30520e592a_h9lakw.png"
            }
          />
            :
            <img
              alt="sign up"
              src={
                "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680194962/web%20images/LogIn_Icons_2_ln9h3y.png"
              }
            />
          }
        </div>
        <FaqSection /> */}
        <FreeTrial />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
